@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#app {
    @apply min-h-screen;
}
